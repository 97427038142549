import { testIds } from "@decentriq/utils";
import { Stack } from "@mui/joy";
import { memo } from "react";
import {
  ConfigurationFormNameField,
  DisplayConfigurationActiveMarketsField,
  DisplayConfigurationCollaborativeUsersField,
  DisplayConfigurationDescriptionField,
  DisplayConfigurationLogoField,
} from "features/mediaPortalShared";
import { usePublisherPortal } from "features/publisherPortal/contexts/PublisherPortalContext/PublisherPortalContext";
import { type SchemaType } from "./model";

const PublisherDisplayConfigurationForm: React.FC = () => {
  const { publisherMarkets, organizationUsers } = usePublisherPortal();
  return (
    <Stack>
      <DisplayConfigurationLogoField<SchemaType>
        fieldName="logo"
        testId={
          testIds.publisherPortal.upsertDisplayConfigurationDialog.logoHelper
        }
      />
      <ConfigurationFormNameField<SchemaType>
        fieldName="publisherName"
        placeholder="Enter organisation name"
        testId={
          testIds.publisherPortal.upsertDisplayConfigurationDialog.publisherName
        }
        title="Publisher name"
      />
      <DisplayConfigurationCollaborativeUsersField<SchemaType>
        fieldName="collaborationRequestUsers"
        optionTestId={
          testIds.publisherPortal.upsertDisplayConfigurationDialog
            .collaborativeUsersSelectorOptionHelper
        }
        organizationUsers={organizationUsers}
        testId={
          testIds.publisherPortal.upsertDisplayConfigurationDialog
            .collaborativeUsersSelector
        }
      />
      <DisplayConfigurationActiveMarketsField<SchemaType>
        activeMarkets={publisherMarkets}
        fieldName="marketIds"
        optionTestId={
          testIds.publisherPortal.upsertDisplayConfigurationDialog
            .activeMarketsSelectorOptionHelper
        }
        testId={
          testIds.publisherPortal.upsertDisplayConfigurationDialog
            .activeMarketsSelector
        }
        tooltip="This is a list of all markets you operate in. Brands can search for publishers in the markets they are interested in and will be able to find and activate with you."
      />
      <DisplayConfigurationDescriptionField<SchemaType>
        fieldName="description"
        placeholder="Enter description"
        testId={
          testIds.publisherPortal.upsertDisplayConfigurationDialog.description
        }
        title="Publisher description"
      />
    </Stack>
  );
};

PublisherDisplayConfigurationForm.displayName =
  "PublisherDisplayConfigurationForm";

export default memo(PublisherDisplayConfigurationForm);
