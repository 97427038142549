import { Box, Typography } from "@mui/joy";
import { memo, useMemo } from "react";
import {
  type AggregationDataModel,
  MediaDataRoomInsightsTable,
  SuppressedSegmentsInformation,
} from "features/mediaDataRoom";
import { useAffinityRatioSegmentDemographicsData } from "features/mediaDataRoom/components/MediaDataRoomInsights/components/MediaDataRoomInsightsDetailedView/hooks";

const AffinityRatioSegmentDemographicsTable = memo(() => {
  const {
    aggregationData: affinityRatioSegmentDemographicsData,
    isEmptyAggregation,
    hasHiddenValues,
    hasSuppressedValues,
    possibleValues,
    suppressedValues,
    hiddenValues,
  } = useAffinityRatioSegmentDemographicsData();

  const affinityRatioSegmentDemographicsTableData = useMemo<
    Partial<AggregationDataModel>[]
  >(
    () =>
      affinityRatioSegmentDemographicsData.map(
        ({
          interest,
          age,
          gender,
          affinityRatio,
        }: Partial<AggregationDataModel>) => ({
          affinityRatio,
          age,
          gender,
          interest,
        })
      ),
    [affinityRatioSegmentDemographicsData]
  );

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      height="100%"
      overflow="hidden"
    >
      <Box alignItems="baseline" display="flex">
        <Typography fontWeight={600} mb={1} mr={1}>
          Full breakdown
        </Typography>
        {(hasSuppressedValues || hasHiddenValues) && (
          <SuppressedSegmentsInformation
            hiddenValues={hiddenValues}
            possibleValues={possibleValues}
            suppressedValues={suppressedValues}
          />
        )}
      </Box>
      <Box overflow="auto" sx={{ flex: 1, maxHeight: 350 }}>
        <MediaDataRoomInsightsTable
          isEmpty={isEmptyAggregation}
          tableData={affinityRatioSegmentDemographicsTableData}
        />
      </Box>
    </Box>
  );
});

AffinityRatioSegmentDemographicsTable.displayName =
  "AffinityRatioSegmentDemographicsTable";

export default AffinityRatioSegmentDemographicsTable;
