import { makeReference, useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { DatasetProvisionDialog } from "@decentriq/components";
import {
  type DatasetReprovisionerProps,
  type ReprovisionArguments,
} from "@decentriq/components/dist/components/DatasetReprovisioner/DatasetReprovisioner";
import { type OnAfterUploadCallback } from "@decentriq/components/dist/components/DatasetUploader/containers";
import { type DatasetUploaderProps } from "@decentriq/components/dist/components/DatasetUploader/DatasetUploader";
import { type VersionedSchema } from "@decentriq/components/dist/components/DatasetUploader/types";
import { UserToken } from "@decentriq/core/dist/api";
import { useDatasetByHashLazyQuery } from "@decentriq/graphql/dist/hooks";
import { type InitArguments } from "@decentriq/uploader";
import { type Key, LabeledPromise } from "@decentriq/utils";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton } from "@mui/joy";
import capitalize from "lodash/capitalize";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { type KeychainItem, KeychainItemKind } from "services/keychain";
import useDataRoomName from "containers/DataRoomNameEditor/useDataRoomName";
import { useConfiguration } from "contexts";
import { DatasetSelector } from "features/dataNodes/components";
import {
  type DataIngestionPayload,
  type DatasetIngestionDefinition,
} from "features/datasets/models";
import {
  fakeGeneratingANewEncryptionKey,
  fakeRetrievingEncryptionKeyFromTheKeychain,
  fakeSettingUpEndToEndEncryption,
} from "features/datasets/utils";
import { type DataRoomTableColumn } from "models";
import { delay, logError } from "utils";
import { idAsHash } from "utils/apicore";
import { useEnclaveToken } from "wrappers";
import { useDataNodeValidate } from "./hooks";

export interface DataNodeUploadDataDialogProps {
  columns?: DataRoomTableColumn[];
  columnsOrder?: string[];
  id: string;
  name: string;
  onClose: () => void;
  onError: (error: Error) => void;
  onIngest: (
    payload: DataIngestionPayload<DatasetIngestionDefinition>
  ) => Promise<void>;
  open: boolean;
  uniqueColumnIds?: string[][];
  skipValidation?: boolean;
}

const DataNodeUploadDataDialog: React.FC<DataNodeUploadDataDialogProps> = memo(
  ({
    columnsOrder = [],
    id,
    name,
    onClose,
    onIngest,
    onError,
    open,
    uniqueColumnIds = [],
    skipValidation = false,
    ...props
  }) => {
    const { name: dcrName } = useDataRoomName();
    const nodeTitle = props.columns ? "dataset" : "file";
    const columns = useMemo(() => {
      return (props.columns || []).sort(
        (a, b) => columnsOrder.indexOf(a.id) - columnsOrder.indexOf(b.id)
      );
    }, [props.columns, columnsOrder]);
    const { user, getAccessTokenSilently } = useAuth0();
    const { enclaveToken } = useEnclaveToken();
    const apolloClient = useApolloClient();
    const { configuration } = useConfiguration();
    const [fetchDataset] = useDatasetByHashLazyQuery({
      fetchPolicy: "network-only",
    });
    const { diswaHost, diswaPort, diswaUseTls } = configuration;
    const [initArguments, setInitArguments] = useState<
      InitArguments | undefined
    >(undefined);
    useEffect(() => {
      (async () => {
        if (user?.email) {
          const platformToken = await getAccessTokenSilently();
          // TODO: Ask for not migrated case https://github.com/decentriq/delta/pull/3326/files#diff-aa40d78b6985678c352658ab976f865303eaddfcdb8b434286972ce237876817
          const token = new UserToken(platformToken, enclaveToken.token);
          setInitArguments({
            host: diswaHost,
            port: diswaPort,
            token: token,
            useTls: diswaUseTls,
            userEmail: user?.email,
          });
        }
      })();
    }, [
      diswaHost,
      diswaPort,
      diswaUseTls,
      enclaveToken,
      getAccessTokenSilently,
      user?.email,
    ]);
    const handleIngest = useCallback(
      async (
        key: Key,
        manifestHash: string,
        schema: VersionedSchema | undefined | null
      ) => {
        await onIngest({
          schema,
          shouldStoreInKeychain: true,
          source: "local",
          uploadResult: {
            key,
            manifestHash,
          },
        });
        try {
          const dataset = await fetchDataset({
            variables: { manifestHash },
          });
          const datasetRef = makeReference(
            apolloClient.cache.identify({
              __typename: "Dataset",
              id: dataset.data!.datasetByManifestHash.id,
            })!
          );
          apolloClient.cache.modify({
            fields: {
              datasets: (existing) => ({
                nodes: [...(existing?.nodes || []), datasetRef],
              }),
            },
          });
        } catch (error) {
          logError(error);
        }
      },
      [onIngest, fetchDataset, apolloClient]
    );
    const validateDataset = useDataNodeValidate({
      columns,
      columnsOrder,
      id,
      name,
      uniqueColumnIds,
    });
    const provisionDataset = useCallback<OnAfterUploadCallback>(
      (uploadArguments, uploadResult, schema) => {
        const promise = new LabeledPromise(async (resolve, reject) => {
          try {
            await Promise.all([
              handleIngest(
                uploadArguments.key,
                uploadResult.manifestHash,
                schema
              ),
              delay(500),
            ]);
            resolve(null);
          } catch (error) {
            reject(error);
          }
        });
        promise.pendingLabel = `Provisioning ${nodeTitle}…`;
        promise.fulfilledLabel = `${capitalize(
          nodeTitle
        )} successfully provisioned`;
        promise.rejectedLabel = `${capitalize(nodeTitle)} provisioning failed`;
        return promise;
      },
      [nodeTitle, handleIngest]
    );
    const DatasetUploaderPropsMemo = useMemo<DatasetUploaderProps>(
      () => ({
        UploadButtonChildren: "Encrypt & Provision",
        UploadButtonProps: {
          color: "primary",
          variant: "contained",
        },
        // @ts-ignore
        initArguments,
        onAfterUpload: [
          ...(columns && columns.length && !skipValidation
            ? [validateDataset]
            : []),
          provisionDataset,
        ],
        onBeforeUpload: [
          fakeSettingUpEndToEndEncryption,
          fakeGeneratingANewEncryptionKey,
        ],
        schema: columns && columns.length ? { columns, uniqueColumnIds } : null,
      }),
      [
        columns,
        initArguments,
        provisionDataset,
        skipValidation,
        uniqueColumnIds,
        validateDataset,
      ]
    );
    const reprovisionDataset = useCallback(
      async (reprovisionArguments: ReprovisionArguments) => {
        const { uploadArguments, uploadResult } = reprovisionArguments;
        const key = uploadArguments.key;
        const manifestHash = uploadResult.manifestHash;
        const datasetKeychainItem: KeychainItem = {
          id: uploadResult.manifestHash,
          kind: KeychainItemKind.Dataset,
          value: idAsHash(uploadArguments.key.material)!,
        };
        await Promise.all([
          await onIngest({
            datasetKeychainItem,
            source: "keychain",
          }),
          delay(500),
        ]);
        return { key, manifestHash };
      },
      [onIngest]
    );
    const DatasetReprovisionerPropsMemo = useMemo<DatasetReprovisionerProps>(
      () => ({
        DatasetSelector,
        onAfterReprovision: undefined,
        onBeforeReprovision: [
          fakeSettingUpEndToEndEncryption,
          fakeRetrievingEncryptionKeyFromTheKeychain,
          ...(columns && columns.length && !skipValidation
            ? [validateDataset]
            : []),
        ],
        reprovisionDataset,
      }),
      [columns, reprovisionDataset, skipValidation, validateDataset]
    );
    return initArguments ? (
      <DatasetProvisionDialog
        DatasetReprovisionerProps={DatasetReprovisionerPropsMemo}
        DatasetUploaderProps={DatasetUploaderPropsMemo}
        DialogTitleChildren={
          <>
            <Box sx={{ lineHeight: 1.25 }}>
              <Box
                sx={{
                  marginBottom: "0.125rem",
                  opacity: 0.75,
                }}
              >
                {dcrName}
              </Box>
              Provision {nodeTitle} to <span>{name}</span>
            </Box>
            <IconButton
              onClick={onClose}
              sx={{ fontSize: "1.25rem", p: 0.5, width: "2rem" }}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faXmark} />
            </IconButton>
          </>
        }
        DialogTitleProps={{
          sx: {
            alignItems: "flex-start",
            display: "flex",
            justifyContent: "space-between",
          },
        }}
        onClose={onClose}
        onError={onError}
        open={open}
      />
    ) : null;
  }
);
DataNodeUploadDataDialog.displayName = "DataNodeUploadDataDialog";

export default DataNodeUploadDataDialog;
