import { useDraftDataRoomActionsQuery } from "@decentriq/graphql/dist/hooks";
import {
  faClone,
  faCopy,
  faEye,
  faEyeSlash,
  faFileDownload,
  faInfoCircle,
  faLink,
  faLockKeyhole,
  faPaintRoller,
  faStar,
  faTrashCan,
} from "@fortawesome/pro-light-svg-icons";
import { faStar as fasStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Switch, Tooltip } from "@mui/joy";
import { Portal, Skeleton } from "@mui/material";
import { useBoolean } from "ahooks";
import { format, isValid, parseISO } from "date-fns";
import { saveAs } from "file-saver";
import { useSnackbar } from "notistack";
import { memo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Actions,
  DataRoomDeleteDialog,
  DataRoomPublishDialog,
} from "components";
import { ActionsRawButton } from "components/base/Actions/Actions";
import { customBrandingLogos } from "components/base/CustomBrandingLogo/CustomBrandingLogo";
import { DataRoomActionTypes } from "features/dataRoom";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import EncryptAndPublishButton from "../EncryptAndPublishButton/EncryptAndPublishButton";
import {
  useDraftDataRoomAsJson,
  useDraftDataRoomCopyLink,
  useDraftDataRoomDelete,
  useDraftDataRoomDuplicate,
  useDraftDataRoomIsFavorite,
  useDraftDataRoomIsHidden,
  useDraftDataRoomPublish,
  useDraftDataRoomRequirePassword,
  useDraftDataRoomShowOrganizationLogo,
} from "./hooks";

interface DataRoomActionsProps {
  id: string;
  [key: string]: any;
}

const DataRoomActions: React.FC<DataRoomActionsProps> = memo(
  ({
    id: dataRoomId,
    actions: actionsFunc = (actions: any) => actions,
    inline = false,
    moreIcon,
  }) => {
    const { data, loading: isDataRoomLoading } = useDraftDataRoomActionsQuery({
      variables: { id: dataRoomId },
    });
    const { draftDataRoom } = data || {};
    const { name, logo, owner, createdAt, updatedAt, password } =
      draftDataRoom || {};
    const {
      email: ownerEmail,
      email: createdByEmail,
      email: updatedByEmail,
      organizationLogo,
    } = owner || {};
    const createdAtDate = parseISO(createdAt);
    const updatedAtDate = parseISO(updatedAt);
    // TODO: override `logo` in `ApolloWrapper` instead
    const domain =
      ownerEmail?.slice(ownerEmail?.indexOf("@") + 1).toLowerCase() || "";
    const hasOrganizationLogo =
      Boolean(logo) || organizationLogo || Boolean(customBrandingLogos[domain]);
    const { enqueueSnackbar: enqueueDataRoomSnackbar } = useDataRoomSnackbar();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // Publish data clean room
    const {
      draftDataRoomPublish,
      draftDataRoomPublishLoading: isDataRoomPublishLoading,
    } = useDraftDataRoomPublish({
      id: dataRoomId,
      password: password || undefined,
    });
    const [publishingWithValidationState, setPublishingWithValidationState] =
      useState<boolean | undefined>(undefined);
    const isDataRoomPublishDialogOpen =
      publishingWithValidationState !== undefined;
    const openDataRoomPublishDialog = useCallback(
      setPublishingWithValidationState,
      [setPublishingWithValidationState]
    );
    const closeDataRoomPublishDialog = useCallback(
      () => setPublishingWithValidationState(undefined),
      [setPublishingWithValidationState]
    );
    const dataRoomPublish = (keepTestData: boolean) =>
      draftDataRoomPublish(!!publishingWithValidationState, keepTestData)
        .then((dataRoomHash) => {
          closeDataRoomPublishDialog();
          enqueueDataRoomSnackbar(
            "Data clean room has been successfully published."
          );
          navigate(`/datarooms/p/${dataRoomHash}`, { replace: true });
        })
        .catch((error) => {
          closeDataRoomPublishDialog();
          enqueueDataRoomSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              `Data clean room could not be published.`
            )
          );
        });
    // Copy link to data clean room
    const { draftDataRoomCopyLink } = useDraftDataRoomCopyLink({
      id: dataRoomId,
    });
    const dataRoomCopyLink = () =>
      draftDataRoomCopyLink()
        .then((wasCopied) => {
          if (wasCopied) {
            enqueueSnackbar(
              <Box sx={{ alignItems: "center", display: "flex" }}>
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={faCopy}
                  style={{ marginRight: "0.5rem" }}
                />
                Copied to clipboard
              </Box>
            );
          }
        })
        .catch((error) => {
          enqueueDataRoomSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Data clean room link could not be generated."
            )
          );
        });
    // Favorite/unfavorite data clean room
    const {
      draftDataRoomIsFavorite: isFavorite,
      draftDataRoomIsFavoriteLoading: isDataRoomIsFavoriteLoading,
      setDraftDataRoomIsFavorite: setIsFavorite,
      setDraftDataRoomIsFavoriteLoading: isSetDataRoomIsFavoriteLoading,
    } = useDraftDataRoomIsFavorite({ id: dataRoomId, skip: isDataRoomLoading });
    const toggleDataRoomIsFavorite = () =>
      setIsFavorite(!isFavorite)
        .then(() => {
          enqueueDataRoomSnackbar(
            `Data clean room has been successfully ${
              !isFavorite ? "added to" : "removed from"
            } Favorites.`
          );
        })
        .catch((error) => {
          enqueueDataRoomSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              `Data clean room could not be ${
                !isFavorite ? "added to" : "removed from"
              } Favorites`
            )
          );
        });
    // Archive/restore (hide/unhide) data clean room
    const {
      isHidden,
      loading: isDataRoomIsHiddenLoading,
      setIsHidden,
      loading: isSetDataRoomIsHiddenLoading,
    } = useDraftDataRoomIsHidden({ id: dataRoomId, skip: isDataRoomLoading });

    const toggleDataRoomIsHidden = () =>
      setIsHidden(!isHidden)
        .then(() => {
          enqueueDataRoomSnackbar(
            `Data clean room was ${!isHidden ? "hidden" : "unhidden"}`
          );
        })
        .catch((error) => {
          enqueueDataRoomSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              `Data clean room could not be ${
                !isHidden ? "hidden" : "unhidden"
              }.`
            )
          );
        });
    // Brand/unbrand data clean room
    const {
      draftDataRoomShowOrganizationLogo: showOrganizationLogo,
      draftDataRoomShowOrganizationLogoLoading:
        isDataRoomShowOrganizationLogoLoading,
      setDraftDataRoomShowOrganizationLogo: setShowOrganizationLogo,
      setDraftDataRoomShowOrganizationLogoLoading:
        isSetDataRoomShowOrganizationLogoLoading,
    } = useDraftDataRoomShowOrganizationLogo({
      id: dataRoomId,
      skip: isDataRoomLoading,
    });
    const toggleDataRoomShowOrganizationLogo = () =>
      setShowOrganizationLogo(!showOrganizationLogo)
        .then(() => {
          enqueueDataRoomSnackbar(
            `Data clean room brand logo has been successfully ${
              !showOrganizationLogo ? "added" : "removed"
            }.`
          );
        })
        .catch((error) => {
          enqueueDataRoomSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              `Data clean room brand logo could not be ${
                !showOrganizationLogo ? "added" : "removed"
              }.`
            )
          );
        });
    // Toggle require password
    const {
      draftDataRoomRequirePassword: requirePassword,
      draftDataRoomRequirePasswordLoading: isDataRoomRequirePasswordLoading,
      setDraftDataRoomRequirePassword: setRequirePassword,
      setDraftDataRoomRequirePasswordLoading:
        isSetDataRoomRequirePasswordLoading,
    } = useDraftDataRoomRequirePassword({
      id: dataRoomId,
      skip: isDataRoomLoading,
    });
    const toggleDataRoomRequirePassword = () =>
      setRequirePassword(!requirePassword)
        .then(() => {
          enqueueDataRoomSnackbar(
            "Data clean room password settings have been successfully updated."
          );
        })
        .catch((error) => {
          enqueueDataRoomSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Data clean room password settings could not be updated."
            )
          );
        });
    // Delete data clean room
    const {
      draftDataRoomDelete,
      draftDataRoomDeleteLoading: isDataRoomDeleteLoading,
    } = useDraftDataRoomDelete({ id: dataRoomId });
    const [
      isDataRoomDeleteDialogOpen,
      {
        setTrue: openDataRoomDeleteDialog,
        setFalse: closeDataRoomDeleteDialog,
      },
    ] = useBoolean(false);
    const dataRoomDelete = () =>
      draftDataRoomDelete()
        .then(() => {
          closeDataRoomDeleteDialog();
          enqueueDataRoomSnackbar(
            "Data clean room has been sucessfully deleted."
          );
          navigate("/datarooms");
        })
        .catch((error) => {
          closeDataRoomDeleteDialog();
          enqueueDataRoomSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              "Data clean room could not be deleted."
            )
          );
        });
    // Export data clean room
    const {
      draftDataRoomAsJson,
      draftDataRoomAsJsonLoading: isDataRoomAsJsonLoading,
    } = useDraftDataRoomAsJson({ id: dataRoomId });
    const dataRoomExportToJson = () =>
      draftDataRoomAsJson()
        .then((json) => {
          if (json) {
            const fileBits: BlobPart[] = [json];
            const fileName = `${name}.json`;
            const options = { type: "application/octet-stream;charset=utf-8" };
            const file = new File(fileBits, fileName, options);
            saveAs(file);
            enqueueDataRoomSnackbar(
              "Data clean room definition has been successfully exported."
            );
          } else {
            throw new TypeError("'json' is undefined.");
          }
        })
        .catch((error) => {
          enqueueDataRoomSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              `Data clean room definition could not be exported.`
            )
          );
        });
    // Duplicate data clean room
    const {
      draftDataRoomDuplicate,
      draftDataRoomDuplicateLoading: isDataRoomDuplicateLoading,
    } = useDraftDataRoomDuplicate({ id: dataRoomId });
    const dataRoomDuplicate = () =>
      draftDataRoomDuplicate()
        .then((dataRoomId) => {
          if (dataRoomId) {
            enqueueDataRoomSnackbar(
              "Data clean room has been sucessfully duplicated."
            );
            navigate(`/datarooms/d/${dataRoomId}`);
          } else {
            throw new TypeError("'dataRoomId' is undefined.");
          }
        })
        .catch((error) => {
          enqueueDataRoomSnackbar(
            ...mapDraftDataRoomErrorToSnackbar(
              error,
              `Data clean room could not be duplicated.`
            )
          );
        });
    // Actions
    const actions = actionsFunc({
      buttons: [
        {
          childComponent: EncryptAndPublishButton,
          component: ActionsRawButton,
          disabled: isDataRoomLoading || isDataRoomPublishLoading,
          loading: isDataRoomPublishLoading,
          name: "Publish",
          onClick: openDataRoomPublishDialog,
          type: DataRoomActionTypes.Publish,
        },
        {
          component: IconButton,
          disabled: isDataRoomLoading || isDataRoomIsFavoriteLoading,
          hidden: !isFavorite,
          icon: isFavorite ? fasStar : faStar,
          loading:
            isDataRoomIsFavoriteLoading || isSetDataRoomIsFavoriteLoading,
          onClick: toggleDataRoomIsFavorite,
          square: true,
          tooltipPlacement: "bottom-end",
          tooltipTitle: isFavorite
            ? "Remove from Favorites"
            : "Add to Favorites",
          type: DataRoomActionTypes.ToggleFavorite,
        },
        {
          component: IconButton,
          disabled: isDataRoomLoading || isDataRoomIsHiddenLoading,
          hidden: !isHidden,
          hover: {
            icon: faEye,
            isRed: false,
            onClick: toggleDataRoomIsHidden,
            tooltipTitle: "Unhide",
          },
          icon: faEyeSlash,
          isRed: false,
          loading: isDataRoomIsHiddenLoading || isSetDataRoomIsHiddenLoading,
          onClick: toggleDataRoomIsHidden,
          square: true,
          tooltipPlacement: "bottom-end",
          tooltipTitle: "Hidden",
          type: DataRoomActionTypes.RestoreOrDelete,
        },
      ],
      menuLists: [
        [
          {
            disabled: isDataRoomLoading || isDataRoomIsFavoriteLoading,
            hidden: false,
            icon: isFavorite ? fasStar : faStar,
            loading:
              isDataRoomIsFavoriteLoading || isSetDataRoomIsFavoriteLoading,
            name: isFavorite ? "Remove from favorites" : "Add to favorites",
            onClick: toggleDataRoomIsFavorite,
            type: DataRoomActionTypes.ToggleFavorite,
          },
          {
            disabled: false,
            icon: faLink,
            name: "Copy link",
            onClick: dataRoomCopyLink,
            type: DataRoomActionTypes.CopyLink,
          },
          {
            disabled: isDataRoomLoading,
            icon: faClone,
            loading: isDataRoomDuplicateLoading,
            name: "Duplicate",
            onClick: dataRoomDuplicate,
            type: DataRoomActionTypes.Duplicate,
          },
          {
            disabled: isDataRoomLoading || isDataRoomIsHiddenLoading,
            icon: isHidden ? faEye : faEyeSlash,
            isRed: false,
            loading: isDataRoomIsHiddenLoading || isSetDataRoomIsHiddenLoading,
            name: isHidden ? "Unhide" : `Hide`,
            onClick: toggleDataRoomIsHidden,
            type: DataRoomActionTypes.HideRestoreOrDelete,
          },
          {
            disabled: false,
            icon: faTrashCan,
            isRed: true,
            loading: isDataRoomDeleteLoading,
            name: "Delete",
            onClick: openDataRoomDeleteDialog,
            tooltipTitle: "Delete",
            type: DataRoomActionTypes.Delete,
          },
        ],
        [
          {
            disabled: isDataRoomLoading,
            icon: faFileDownload,
            loading: isDataRoomAsJsonLoading,
            name: "Export to JSON",
            onClick: dataRoomExportToJson,
            type: DataRoomActionTypes.Export,
          },
        ],
        [
          {
            disabled: isDataRoomLoading,
            hidden: !hasOrganizationLogo,
            icon: faPaintRoller,
            loading:
              isDataRoomShowOrganizationLogoLoading ||
              isSetDataRoomShowOrganizationLogoLoading,
            name: (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flex: "1 1 auto",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ paddingRight: 8 }}>
                  Brand data clean room with org logo
                </span>
                <Switch checked={showOrganizationLogo} />
              </div>
            ),
            onClick: toggleDataRoomShowOrganizationLogo,
            type: DataRoomActionTypes.ShowOrganizationLogo,
          },
        ],
        [
          {
            icon: faLockKeyhole,
            loading:
              isDataRoomRequirePasswordLoading ||
              isSetDataRoomRequirePasswordLoading,
            name: (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flex: "1 1 auto",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ marginRight: 8 }}>
                  Set data clean room password
                </span>
                <Tooltip title="Password is an additional security measure. When set, participants must provide the password to interact with the data clean room. You will be asked to create a password when publishing this data clean room.">
                  <span style={{ display: "flex", marginRight: 8 }}>
                    <FontAwesomeIcon fixedWidth={true} icon={faInfoCircle} />
                  </span>
                </Tooltip>
                <Switch checked={requirePassword} />
              </div>
            ),
            onClick: toggleDataRoomRequirePassword,
            type: DataRoomActionTypes.RequirePassword,
          },
        ],
        [
          {
            name: (
              <div
                style={{
                  lineHeight: "1.1875rem",
                  opacity: 0.75,
                  overflow: "auto",
                  whiteSpace: "pre",
                }}
              >
                {isValid(createdAtDate) ? (
                  <div>
                    <div>
                      <strong>Created by</strong>
                    </div>
                    <div>{createdByEmail}</div>
                    <div>on {format(createdAtDate, "dd-MM-yyyy HH:mm:ss")}</div>
                  </div>
                ) : null}
                {isValid(updatedAtDate) ? (
                  <div>
                    <div style={{ marginTop: 4 }}>
                      <strong>Last edited by</strong>
                    </div>
                    <div>{updatedByEmail}</div>
                    <div>on {format(updatedAtDate, "dd-MM-yyyy HH:mm:ss")}</div>
                  </div>
                ) : null}
              </div>
            ),
            type: DataRoomActionTypes.Details,
          },
        ],
      ],
    });
    const actionsTypes = [actions.buttons || [], actions.menuLists || []]
      .flat(Infinity)
      .map(({ type }) => type);
    return isDataRoomLoading ? (
      <Skeleton
        height={inline ? 24 : 32}
        variant="rectangular"
        width={inline ? 24 : 32}
      />
    ) : (
      <Actions
        actions={actions}
        inline={inline}
        moreIcon={moreIcon}
        moreTooltipTitle=""
      >
        <Portal>
          {actionsTypes.includes(DataRoomActionTypes.Publish) ? (
            <DataRoomPublishDialog
              dataRoomId={dataRoomId}
              loading={isDataRoomPublishLoading}
              onCancel={closeDataRoomPublishDialog}
              onConfirm={dataRoomPublish}
              open={isDataRoomPublishDialogOpen}
              requirePassword={requirePassword}
            />
          ) : null}
          {actionsTypes.includes(DataRoomActionTypes.HideRestoreOrDelete) ? (
            <DataRoomDeleteDialog
              loading={isDataRoomDeleteLoading}
              name={name || ""}
              onCancel={closeDataRoomDeleteDialog}
              onConfirm={dataRoomDelete}
              open={isDataRoomDeleteDialogOpen}
            />
          ) : null}
        </Portal>
      </Actions>
    );
  }
);

export default DataRoomActions;
