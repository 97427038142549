import { ProgressPromise } from "@decentriq/utils";
import { delay } from "utils";

export * from "./statistics";

export function formatSize(bytes: number = 0) {
  const units = [
    "byte",
    "kilobyte",
    "megabyte",
    "gigabyte",
    "terabyte",
    "petabyte",
  ];
  const unit = bytes === 0 ? 0 : Math.floor(Math.log(bytes) / Math.log(1024));
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 1,
    style: "unit",
    unit: units[unit],
    unitDisplay: "narrow",
  }).format(bytes / 1024 ** unit);
}

export function formatRows(num: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 1,
    notation: "compact",
  });
  return formatter.format(num);
}

export const fakeSettingUpEndToEndEncryption = () => {
  const promise = new ProgressPromise(async (resolve, reject, progress) => {
    for (let i = 0; i <= 100; i++) {
      progress(i / 100);
      await delay(10);
    }
    resolve(undefined);
  });
  promise.pendingLabel = (
    <span>
      Setting up end-to-end encryption
      <br />
      with the enclave…
    </span>
  ) as unknown as string;
  promise.fulfilledLabel =
    "End-to-end encryption with the enclave set up successfully";
  promise.rejectedLabel =
    "Failed to set up end-to-end encryption with the enclave";
  return promise;
};

export const fakeGeneratingANewEncryptionKey = () => {
  const promise = new ProgressPromise(async (resolve, reject, progress) => {
    progress(0.03);
    await delay(110);
    progress(0.14);
    await delay(500);
    progress(0.37);
    await delay(250);
    progress(0.41);
    await delay(800);
    progress(0.93);
    await delay(700);
    progress(1.0);
    resolve(undefined);
  });
  promise.pendingLabel = "Generating a new encryption key…";
  promise.fulfilledLabel = "A new encryption key is generated";
  promise.rejectedLabel = "Failed to generate a new encryption key";
  return promise;
};

export const fakeRetrievingEncryptionKeyFromTheKeychain = () => {
  const promise = new ProgressPromise(async (resolve, reject, progress) => {
    progress(0.03);
    await delay(110);
    progress(0.14);
    await delay(500);
    progress(0.37);
    await delay(250);
    progress(0.41);
    await delay(800);
    progress(0.93);
    await delay(700);
    progress(1.0);
    resolve(undefined);
  });
  promise.pendingLabel = "Retrieving encryption key from the Keychain…";
  promise.fulfilledLabel = "Encryption key was retrieved from the Keychain";
  promise.rejectedLabel =
    "Failed to retrieve the encryption key from the Keychain";
  return promise;
};
