import { Box, Typography } from "@mui/joy";
import { memo, useMemo } from "react";
import {
  type AggregationData,
  MediaDataRoomInsightsTable,
  SuppressedSegmentsInformation,
} from "features/MediaInsightsDcr";
import { useAffinityRatioSegmentDemographicsData } from "features/MediaInsightsDcr/components/MediaDataRoomInsights/components/MediaDataRoomInsightsDetailedView/hooks";

const AffinityRatioSegmentDemographicsTable: React.FC = () => {
  const {
    aggregationData: affinityRatioSegmentDemographicsData,
    isEmptyAggregation,
    hasHiddenValues,
    hasSuppressedValues,
    possibleValues,
    suppressedValues,
    hiddenValues,
  } = useAffinityRatioSegmentDemographicsData();

  const affinityRatioSegmentDemographicsTableData = useMemo<
    Partial<AggregationData>[]
  >(
    () =>
      affinityRatioSegmentDemographicsData.map(
        ({
          interest,
          age,
          gender,
          affinityRatio,
        }: Partial<AggregationData>) => ({
          affinityRatio,
          age,
          gender,
          interest,
        })
      ),
    [affinityRatioSegmentDemographicsData]
  );

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      height="100%"
      overflow="hidden"
    >
      <Box alignItems="baseline" display="flex">
        <Typography fontWeight={600} mb={1} mr={1}>
          Full breakdown
        </Typography>
        {(hasSuppressedValues || hasHiddenValues) && (
          <SuppressedSegmentsInformation
            hiddenValues={hiddenValues}
            possibleValues={possibleValues}
            suppressedValues={suppressedValues}
          />
        )}
      </Box>
      <Box overflow="auto" sx={{ flex: 1, maxHeight: 350 }}>
        <MediaDataRoomInsightsTable
          isEmpty={isEmptyAggregation}
          tableData={affinityRatioSegmentDemographicsTableData}
        />
      </Box>
    </Box>
  );
};

AffinityRatioSegmentDemographicsTable.displayName =
  "AffinityRatioSegmentDemographicsTable";

export default memo(AffinityRatioSegmentDemographicsTable);
