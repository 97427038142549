import { Grid } from "@mui/joy";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  type InsightsDetailedViewFormValues,
  MediaDataRoomInsightsAdvertiserAudienceSelector,
} from "features/mediaDataRoom";
import { useMediaDataRoomOverlapInsights } from "features/mediaDataRoom/contexts";
import {
  AffinityRatioSegmentDemographicsBarChart,
  AffinityRatioSegmentDemographicsTable,
  GenderBarChart,
} from "./components";

const MediaDataRoomInsightsDetailedView = memo(() => {
  const { audienceType } = useMediaDataRoomOverlapInsights();
  const form = useForm<InsightsDetailedViewFormValues>({
    defaultValues: {
      audienceType: audienceType || "",
      dataSortDirection: "desc",
      genderChartValueKey: "shareInOverlap",
    },
    mode: "onChange",
  });
  return (
    <FormProvider {...form}>
      <Grid container={true}>
        <Grid mb={1} md={4} xs={12}>
          <MediaDataRoomInsightsAdvertiserAudienceSelector />
        </Grid>
        <Grid mb={2} minHeight={250} xs={12}>
          <AffinityRatioSegmentDemographicsBarChart />
        </Grid>
        <Grid columnSpacing={5} container={true} xs={12}>
          <Grid mb={2} md={6} minHeight={230} xs={12}>
            <GenderBarChart />
          </Grid>
          <Grid md={6} xs={12}>
            <AffinityRatioSegmentDemographicsTable />
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
});

MediaDataRoomInsightsDetailedView.displayName =
  "MediaDataRoomInsightsDetailedView";

export default MediaDataRoomInsightsDetailedView;
